import './CreateAuthLinkModal.scss';
import { Button, Modal } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import AuthService from '../../../Services/AuthService';
import ClientService from '../../../Services/ClientService';
import XpektorButton from '../../../Components/XpektorButton/XpektorButton';
import XpektorForm from '../../../Components/XpektorForm/XpektorForm';

/* eslint-disable react/prop-types */
export default function CreateAuthLinkModal({ handleClose, handleNewLink }) {
  const [authUrl, setAuthUrl] = useState(null);
  const [creatingLink, setCreatingLink] = useState(false);

  const [orgNumberErrorText, setOrgNumberErrorText] = useState(null);
  const [orgNumber, setOrgNumber] = useState('');

  const [enableDataFetch, setEnableDataFetch] = useState(false);
  const [dataFetchErrorText, setDataFetchErrorText] = useState(null);
  const [fetchingData, setFetchingData] = useState(false);
  const [companyInfo, setCompanyInfo] = useState(null);

  const [animatingCopied, setAnimatingCopied] = useState(false);
  const [animatingCopiedCount, setAnimatingCopiedCount] = useState(0);

  function ValidateOrgNumber(org) {
    const validLength = org.length === 10;

    if (!validLength) {
      setOrgNumberErrorText('Organisationsnummret måste bestå av 10 siffror');
    } else {
      setOrgNumberErrorText('');
    }

    setEnableDataFetch(validLength === true);
    return validLength;
  }

  function SetOrgNumberInput(newValue) {
    if (newValue.length <= 10) {
      setOrgNumber(newValue);
      ValidateOrgNumber(newValue);
    }
  }

  useEffect(() => {
    const animateCopied = () => {
      const newAnimatingCopiedCount = animatingCopiedCount + 1;
      if (newAnimatingCopiedCount === 2) {
        setAnimatingCopiedCount(0);
        setAnimatingCopied(false);
      } else {
        setAnimatingCopiedCount(newAnimatingCopiedCount);
      }
    };
    const timer = setTimeout(() => animatingCopied && animateCopied(), 1000);
    return () => clearTimeout(timer);
  }, [animatingCopiedCount, animatingCopied]);

  const createClientAndGetLink = async () => {
    setCreatingLink(true);

    await ClientService.createClient(
      companyInfo.name,
      companyInfo.organizationNumber
    )
      .then((response) => {
        return AuthService.generateExternalAuthUrlForClient(
          response.data.id,
          null
        );
      })
      .then((response) => {
        setAuthUrl(response.data);
        if (handleNewLink != null) {
          handleNewLink();
        }
      })
      .catch(() => {})
      .finally(() => {
        setCreatingLink(false);
      });
  };

  const getCompanyData = async (event) => {
    event.preventDefault();

    setFetchingData(true);

    await ClientService.getBasicCompanyInfo(orgNumber)
      .then((response) => {
        if (response.data.errorCode === null) {
          setCompanyInfo(response.data);
          setDataFetchErrorText(null);
        } else {
          setCompanyInfo(null);
          setDataFetchErrorText(response.data.errorCode);
        }
      })
      .catch((error) => {
        setCompanyInfo(null);
        if (error.response.status === 404) {
          setDataFetchErrorText(
            'Hittade inget företag med det organisationsnummret.'
          );
        } else {
          setDataFetchErrorText('Kunde inte hämta företaget');
        }
      })
      .finally(() => {
        setAuthUrl(null);
        setFetchingData(false);
      });
  };

  const copyLink = () => {
    navigator.clipboard.writeText(authUrl);
  };

  return (
    <Modal
      show
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      className="border-0 create-auth-link-modal"
    >
      <Modal.Header>
        <Modal.Title>Lägg till klient</Modal.Title>
        <Button className="btn-close" bsPrefix="button" onClick={handleClose} />
      </Modal.Header>
      <Modal.Body className="border-0">
        <div className="auth-link-modal-content">
          <p className="auth-link-description">
            Lägg till en organisation och få en autentiseringslänk som kan
            skickas till en klient där de kan ge Xpektor behörighet att hämta
            data om deras företag.
            <br />
            <br />
            Länken är giltig i 30 dagar och du kommer att få ett mail varje gång
            klienten kopplar på något.
          </p>
          <div className="company-info-content">
            <XpektorForm onSubmit={getCompanyData}>
              <XpektorForm.Input
                label="Org. nummer (10 siffror)"
                type="number"
                placeholder="Ange organisationsnummer"
                value={orgNumber}
                onChange={(e) => {
                  SetOrgNumberInput(e.target.value);
                }}
                infoText={orgNumberErrorText}
              />
              <XpektorForm.Button
                text={fetchingData ? 'Hämtar...' : 'Hämta företag'}
                disabled={!enableDataFetch || fetchingData}
              />
            </XpektorForm>
            {(companyInfo !== null || dataFetchErrorText !== null) && (
              <div
                className={`company-info-display ${
                  dataFetchErrorText != null && 'company-info-error'
                }`}
              >
                <p className="company-name">
                  {dataFetchErrorText ?? companyInfo?.name}
                </p>
                {companyInfo?.organizationNumber != null && (
                  <p className="company-org-number">
                    {companyInfo.organizationNumber}
                  </p>
                )}
              </div>
            )}
          </div>
          {companyInfo !== null && companyInfo.errorCode === null && (
            <div className="auth-link-content">
              <XpektorButton
                disabled={authUrl !== null}
                loading={creatingLink}
                content="Skapa länk för klient"
                onClick={() => {
                  createClientAndGetLink();
                }}
              />
              {authUrl !== null && (
                <div className="auth-link-display">
                  <XpektorButton
                    content={
                      animatingCopied ? (
                        <FontAwesomeIcon
                          className="copied-check-icon"
                          icon={faCheck}
                        />
                      ) : (
                        'Kopiera'
                      )
                    }
                    onClick={() => {
                      copyLink();
                      setAnimatingCopied(true);
                    }}
                  />
                  <p className="auth-link-url">{authUrl}</p>
                </div>
              )}
            </div>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
}
