/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-alert */
import './ExternalAuthorization.scss';
import { useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCircleCheck,
  faArrowRight,
  faArrowLeft,
  faCheck,
} from '@fortawesome/free-solid-svg-icons';
import BankService from '../../Services/BankService';
import ClientService from '../../Services/ClientService';
import CustomerService from '../../Services/CustomerService';
import SKVService from '../../Services/SKVService';
import AuthService from '../../Services/AuthService';
import XpektorLogo from '../../Images/Xpektor.png';
import XpektorModal from '../../Components/XpektorModal/XpektorModal';
import XpektorButton from '../../Components/XpektorButton/XpektorButton';
import OnboardingIconButton from '../../Components/OnboardingIconButton/OnboardingIconButton';
import LoadingBar from '../../Components/LoadingBar/LoadingBar';
import AccountingAuthStep from './AuthStates/AccountingAuthStep';
import BankAuthStep from './AuthStates/BankAuthStep';
import SkvAuthStep from './AuthStates/SkvAuthStep';
import {
  AuthStepDisplay,
  AuthStepIcon,
  AuthStepIconLine,
} from './ExternalAuthComponents';

export default function ExternalAuthorization() {
  const [authState, setAuthState] = useState('');
  const [loadingText, setLoadingText] = useState('Vänligen vänta...');

  const [errorText, setErrorText] = useState('');
  const [authErrorType, setAuthErrorType] = useState('');
  const [authErrorText, setAuthErrorText] = useState(null);

  const [externalAuthState, setExternalAuthState] = useState(null);

  const { search } = useLocation();
  const [logo, setLogo] = useState('');

  const authCode = new URLSearchParams(search).get('authCode');
  const partnerId = new URLSearchParams(search).get('partnerId');
  const action = new URLSearchParams(search).get('action');
  const state = new URLSearchParams(search).get('state');
  const ext = new URLSearchParams(search).get('ext');
  const errorType = new URLSearchParams(search).get('error_type');

  // Bank stuff
  const [banks, setBanks] = useState([]);

  const countries = [
    { name: 'Denmark', code: 'DK' },
    { name: 'Finland', code: 'FI' },
    { name: 'Norway', code: 'NO' },
    { name: 'Sweden', code: 'SE' },
  ];
  const [selectedCountry, setSelectedCountry] = useState({
    name: 'Sweden',
    code: 'SE',
  });

  /* const [pollingOmbud, setPollingOmbud] = useState(false);
  const [count, setCount] = useState(0);
  const pollOmbud = async () => {
    setCount(count + 1);
    await SKVService.isXpektorOmbudForClient(
      externalAuthState.targetClient
    ).then((response) => {
      if (response.data === true) {
        AuthService.getExternalAuthState(externalAuthState.id)
          .then((authStateResponse) => {
            const updateAuthStateRequest = {
              id: authStateResponse.data.id,
              targetClient: authStateResponse.data.targetClient,
            };
            AuthService.updateExternalAuthState(updateAuthStateRequest)
              .then((updatedResponse) => {
                setExternalAuthState(updatedResponse.data);
                setAuthState('completed');
              })
              .catch(() => {})
              .finally(() => {
                setPollingOmbud(false);
              });
          })
          .catch(() => {});
      }
    });
  };

  useEffect(() => {
    const timer = setTimeout(() => pollingOmbud && pollOmbud(), 3000);
    return () => clearTimeout(timer);
  }, [count, pollingOmbud]); */

  async function GetBanks(countryCode) {
    await BankService.getBanks(countryCode)
      .then((response) => {
        setBanks(response.data);
        return true;
      })
      .catch((error) => {
        setErrorText('Oops, något gick fel.');
        setAuthState('error');
      });
    return false;
  }

  async function OnExternalAuthStateFetch(extAuthState) {
    if (extAuthState.bankConnected === false) {
      await GetBanks(selectedCountry.code)
        .then(() => setAuthState('bank'))
        .catch(() => {});
    } else if (extAuthState.accountingConnected === false) {
      setAuthState('bokföring');
    } else if (extAuthState.skvConnected === false) {
      setAuthState('skatteverket');
    } else {
      setAuthState('completed');
    }
  }

  useEffect(() => {
    const decodeState = async () => {
      if (state) {
        const response = await AuthService.decodeExternal(state);
        if (response != null) {
          setExternalAuthState(response);
          await OnExternalAuthStateFetch(response);
        } else {
          window.location = `/authorization?action=unauthorized`;
        }
      }
    };
    decodeState();
  }, [state]);

  useEffect(() => {
    const getData = async () => {
      CustomerService.getOnboardingInfo()
        .then((response) => {
          setLogo(response.data.logo);
        })
        .catch((error) => {
          if (error.response.status === 404) {
            alert('Hittar inte kunden');
          }
          if (error.response.status === 500) {
            alert('Något gick fel försök igen om en stund');
          }
        });
    };
    getData();
  }, []);

  useEffect(() => {
    const onAction = async () => {
      setAuthState('loading');

      if (action === 'auth') {
        await AuthService.getExternalAuthState(ext)
          .then((response) => {
            if (errorType !== null) {
              const errorDescription = new URLSearchParams(search).get(
                'error_description'
              );

              if (errorType === 'accounting') {
                setAuthState('bokföring');
                setAuthErrorType('accounting');
              } else if (errorType === 'bank') {
                GetBanks(selectedCountry.code)
                  .then(() => setAuthState('bank'))
                  .catch(() => {});
                setAuthErrorType('bank');
                if (errorDescription !== null) {
                  setAuthErrorText(
                    `Något gick fel och det gick inte att koppla banken. Felmeddelande: ${errorDescription}`
                  );
                }
              }
            } else {
              OnExternalAuthStateFetch(response.data);
            }
            setExternalAuthState(response.data);
          })
          .catch(() => {});
      } else if (action === 'createClient') {
        // Land here after being redirected back from accounting auth
        await ClientService.authenticateAccounting(
          authCode,
          partnerId !== null ? Number(partnerId) : null,
          null,
          ext
        )
          .then(() => {
            window.location = `/authorization?action=auth&ext=${ext}`;
          })
          .catch((error) => {
            AuthService.getExternalAuthState(ext).then((response) => {
              setExternalAuthState(response.data);
              setAuthState('bokföring');
              setAuthErrorType('accounting');
            });

            if (error.response.status === 406) {
              // setErrorText(`Du har inte behörighet att autentisera den nya klienten. Anledning: Du saknar licens för
              // ${translateAuthenticationError(error.response.data.message)}`);
            }
          });
      } else if (action === 'completed') {
        setAuthState('completed');
      } else if (action === 'unauthorized') {
        setAuthState('error');
        setErrorText('Ej giltig länk');
      }
    };
    onAction();
  }, [action]);

  const redirectToFortnox = async () => {
    await AuthService.getAuthUrlFortnox(
      4, // Redirect back to external auth page for fortnox client
      null, // Client Id. Not yet created.
      0, // Create client
      externalAuthState.id // External auth state id to keep track of
    ).then((response) => {
      if (response) {
        window.location.replace(response);
      }
    });
  };

  const redirectToVisma = async () => {
    await AuthService.getAuthUrlVisma(
      4, // Redirect back to external auth page for visma client
      null, // Client Id. Not yet created.
      0, // Create client
      externalAuthState.id // External auth state id to keep track of
    ).then((response) => {
      if (response) {
        window.location.replace(response);
      }
    });
  };

  const redirectToBank = async (bankName, bankCountry, psuType) => {
    await BankService.getAuthLinkForEnableBanking(
      externalAuthState.targetClient,
      bankName,
      bankCountry,
      psuType,
      externalAuthState.id
    )
      .then((response) => {
        if (response) {
          window.location.replace(response.data);
        }
      })
      .catch((error) => {
        setAuthState('error');
        setAuthErrorType('bank');
        setAuthErrorText('Kunde inte omdirigera till bank, försök igen senare');
      });
  };

  const openSkvTab = async () => {
    setLoadingText('Omdirigerar...');
    setAuthState('loading');
    await SKVService.getDeepLinkSKV(externalAuthState.targetClient)
      .then((response) => {
        window.open(response.data, '_blank', 'noopener,noreferrer');
      })
      .catch(() => {
        setAuthErrorType('skv');
        setAuthErrorText('Kunde inte omdirigera till Skatteverket.');
        setAuthState('skatteverket');
      });
  };

  async function NextStep() {
    if (authState === 'bank') {
      setAuthState('bokföring');
    } else if (authState === 'bokföring') {
      setAuthState('skatteverket');
    }
  }

  async function PreviousStep() {
    if (authState === 'skatteverket') {
      setAuthState('bokföring');
    } else if (authState === 'bokföring') {
      if (banks.length === 0 && externalAuthState.bankConnected === false) {
        await GetBanks(selectedCountry.code)
          .then(() => setAuthState('bank'))
          .catch(() => {});
      } else {
        setAuthState('bank');
      }
    }
  }

  return (
    <div className="external-auth-wrapper">
      <XpektorModal>
        {logo && (
          <div className="auth-header">
            <img className="logo" src={logo} alt="Logo" />
          </div>
        )}
        <XpektorModal.Body>
          {authState === 'loading' ? (
            <LoadingBar active status={loadingText} />
          ) : (
            externalAuthState !== null && (
              <>
                <AuthStepDisplay
                  step={
                    authState === 'bokföring'
                      ? 1
                      : authState === 'bank'
                      ? 2
                      : authState === 'skatteverket'
                      ? 3
                      : 0
                  }
                >
                  <AuthStepIcon
                    selected={authState === 'bank'}
                    connected={externalAuthState.bankConnected === true}
                    text="Bankkonto"
                  />
                  <AuthStepIconLine
                    active={externalAuthState.bankConnected === true}
                  />
                  <AuthStepIcon
                    selected={authState === 'bokföring'}
                    connected={externalAuthState.accountingConnected === true}
                    text="Bokföringssystem"
                  />
                  <AuthStepIconLine
                    active={externalAuthState.accountingConnected === true}
                  />
                  <AuthStepIcon
                    selected={authState === 'skatteverket'}
                    connected={externalAuthState.skvConnected === true}
                    text="Skattekonto"
                  />
                </AuthStepDisplay>
                {authState === 'bokföring' && (
                  <AccountingAuthStep
                    completed={externalAuthState.accountingConnected === true}
                    redirectToFortnox={redirectToFortnox}
                    redirectToVisma={redirectToVisma}
                    error={authErrorType === 'accounting'}
                  />
                )}
                {authState === 'bank' && (
                  <BankAuthStep
                    completed={externalAuthState.bankConnected === true}
                    banks={banks}
                    countries={countries}
                    selectedCountry={selectedCountry}
                    onCountryClick={(country) => {
                      if (country.name !== selectedCountry.name) {
                        setSelectedCountry(country);
                        setBanks([]);
                        GetBanks(country.code);
                      }
                    }}
                    redirectToBank={redirectToBank}
                    error={authErrorType === 'bank'}
                    errorText={authErrorText}
                  />
                )}
                {authState === 'skatteverket' && (
                  <SkvAuthStep
                    allowed={
                      externalAuthState.accountingConnected === true ||
                      externalAuthState.bankConnected === true
                    }
                    completed={externalAuthState.skvConnected === true}
                    openSkvTab={openSkvTab}
                    error={authErrorType === 'skv'}
                    errorText={authErrorText}
                  />
                )}
                {authState !== 'error' && authState !== 'completed' && (
                  <div className="auth-step-nav">
                    {authState !== 'bank' && (
                      <OnboardingIconButton
                        content="Gå tillbaka"
                        icon={faArrowLeft}
                        iconPosition="left"
                        onClick={() => PreviousStep()}
                      />
                    )}
                    {authState === 'bank' && <div />}
                    {authState !== 'skatteverket' ? (
                      externalAuthState.targetClient !== null && (
                        <OnboardingIconButton
                          content={
                            (authState === 'bokföring' &&
                              !externalAuthState.accountingConnected) ||
                            (authState === 'bank' &&
                              !externalAuthState.bankConnected)
                              ? 'Hoppa över'
                              : 'Nästa'
                          }
                          onClick={() => NextStep()}
                          icon={faArrowRight}
                          iconPosition="right"
                        />
                      )
                    ) : externalAuthState.accountingConnected === true ||
                      externalAuthState.bankConnected === true ? (
                      <XpektorButton
                        icon={faCheck}
                        content="Slutför"
                        onClick={() => setAuthState('completed')}
                      />
                    ) : (
                      ''
                    )}
                  </div>
                )}
              </>
            )
          )}
          {authState === 'error' && (
            <div className="auth-step">
              <div className="auth-default-error">
                <p>{errorText}</p>
              </div>
            </div>
          )}
          {authState === 'completed' && (
            <div className="auth-step">
              <div className="auth-step-completed-content">
                <FontAwesomeIcon
                  className="auth-complete-icon"
                  icon={faCircleCheck}
                />
                <p className="auth-complete-header">
                  {`Vi uppskattar att du har kopplat ${
                    externalAuthState.accountingConnected &&
                    externalAuthState.bankConnected &&
                    externalAuthState.skvConnected
                      ? 'bokföring, bank och Skatteverket'
                      : !externalAuthState.accountingConnected
                      ? 'ditt företagskonto'
                      : !externalAuthState.bankConnected
                      ? 'din bokföring'
                      : 'din bokföring och bank'
                  } till
                  Xpektor!`}
                </p>
                <p className="auth-complete-text">
                  {externalAuthState.accountingConnected &&
                  externalAuthState.bankConnected &&
                  externalAuthState.skvConnected
                    ? 'Alla steg är nu slutförda och ditt ärende är under handläggning.'
                    : !externalAuthState.accountingConnected
                    ? 'För en smidigare handläggning rekommenderar vi att du även kopplar bokföringen och Skatteverket. Du kan enkelt använda samma länk för att slutföra kopplingen.'
                    : !externalAuthState.bankConnected
                    ? 'För en smidigare handläggning rekommenderar vi att du även kopplar banken och Skatteverket. Du kan enkelt använda samma länk för att slutföra kopplingen.'
                    : 'För en smidigare handläggning rekommenderar vi att du även kopplar Skatteverket. Du kan enkelt använda samma länk för att slutföra kopplingen.'}
                </p>
              </div>
            </div>
          )}
        </XpektorModal.Body>
        <div className="auth-footer">
          <p className="why-link">
            Varför är denna information viktig?{' '}
            <a href="/why" target="_blank" rel="noopener noreferrer">
              Läs mer
            </a>
          </p>
          <div className="powered-by">
            <p>Powered by</p>
            <img className="logo" src={XpektorLogo} alt="XpektorLogo" />
          </div>
        </div>
      </XpektorModal>
    </div>
  );
}
