import './AuthLinkModal.scss';
import { Button, Modal } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import AuthService from '../../../../Services/AuthService';
import XpektorButton from '../../../../Components/XpektorButton/XpektorButton';

/* eslint-disable react/prop-types */
export default function AuthLinkModal({ authLink, handleClose, handleEdit }) {
  const [authUrl, setAuthUrl] = useState('');

  const [animatingCopied, setAnimatingCopied] = useState(false);
  const [animatingCopiedCount, setAnimatingCopiedCount] = useState(0);

  const [deletingLink, setDeletingLink] = useState(false);

  useEffect(() => {
    const animateCopied = () => {
      const newAnimatingCopiedCount = animatingCopiedCount + 1;
      if (newAnimatingCopiedCount === 2) {
        setAnimatingCopiedCount(0);
        setAnimatingCopied(false);
      } else {
        setAnimatingCopiedCount(newAnimatingCopiedCount);
      }
    };
    const timer = setTimeout(() => animatingCopied && animateCopied(), 1000);
    return () => clearTimeout(timer);
  }, [animatingCopiedCount, animatingCopied]);

  useEffect(() => {
    setAuthUrl(
      `${window.location.protocol}//${window.location.host}/authorization?state=${authLink.id}`
    );
  }, [authLink]);

  const copyLink = () => {
    navigator.clipboard.writeText(authUrl);
  };

  const deleteLink = async () => {
    setDeletingLink(true);
    await AuthService.deleteExternalAuthState(authLink.id)
      .then(() => {
        handleEdit();
        handleClose();
      })
      .catch(() => {});
  };

  return (
    <Modal
      show
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      className="border-0 auth-link-modal"
    >
      <Modal.Header>
        <Modal.Title>{`Länk: ${authLink.clientName}`}</Modal.Title>
        <Button className="btn-close" bsPrefix="button" onClick={handleClose} />
      </Modal.Header>
      <Modal.Body className="border-0">
        <div className="auth-link-display">
          <XpektorButton
            content={
              animatingCopied ? (
                <FontAwesomeIcon className="copied-check-icon" icon={faCheck} />
              ) : (
                'Kopiera'
              )
            }
            onClick={() => {
              copyLink();
              setAnimatingCopied(true);
            }}
          />
          <p className="auth-link-url">{authUrl}</p>
        </div>
        <div className="auth-link-modal-footer">
          <XpektorButton
            loading={deletingLink}
            content="Ta bort"
            onClick={() => {
              deleteLink();
            }}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
}
