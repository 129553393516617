import './Dashboard.scss';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Moment from 'moment';
import UserService from '../../../Services/UserService';
import StatusService from '../../../Services/StatusService';
import ClientService from '../../../Services/ClientService';
import ConnectAccountingModal from '../Modals/ConnectAccountingModal';
import CompanyTable from './CompanyTable/CompanyTable';
import LoadingWindow from '../../../Components/LoadingBar/LoadingBar';
import LabeledInfo from '../../../Components/LabeledInfo/LabeledInfo';
import XpektorButton from '../../../Components/XpektorButton/XpektorButton';
import CreateAuthLinkModal from '../Modals/CreateAuthLinkModal';
import Dropdown from '../../../Components/Dropdown/Dropdown';

/* eslint-disable no-alert */
export default function Dashboard() {
  const [clients, setClients] = useState([]);
  const [scores, setScores] = useState([]);
  const [authState, setAuthState] = useState({});
  const history = useHistory();
  const { search } = window.location;
  const query = new URLSearchParams(search).get('s');
  const [action, setAction] = useState('');
  const [clientToCreate, setClientToCreate] = useState([]);
  const [showAuthenticateClient, setShowAuthenticateClient] = useState(false);
  const [showCreateAuthLinkModal, setShowCreateAuthLinkModal] = useState(false);

  const [isLoading, setLoadingState] = useState(false);
  const [loadingText, setLoadingText] = useState('');
  const [lastUpdated, setLastUpdated] = useState('N/A');
  const [clientDropdownText, setClientDropdownText] =
    useState('Kopplade klienter');
  const [clientDropdownOpen, setClientDropdownOpen] = useState(false);
  const [connectionFilter, setConnectionFilter] = useState('');

  useEffect(() => {
    const getCompanyStatuses = async () => {
      setLoadingState(true);
      setLoadingText('Hämtar klienter...');
      try {
        await ClientService.getAllClients()
          .then((response) => {
            setClients(response.data);
            setLoadingText('Hämtar data för klienter...');
            return StatusService.getScores();
          })
          .then((response) => {
            setScores(response.data);
            setLoadingState(false);
            setLastUpdated(Moment.utc().local().format('YYYY-MM-DD HH:mm'));
          });
      } catch (error) {
        if (error.response.status === 404) {
          alert('Inga klienter kopplade till användare');
        }
        if (error.response.status === 500) {
          alert('Något gick fel försök igen om en stund');
        }
        setLoadingState(false);
      }
      const user = UserService.getCurrentUser();
      setAuthState((state) => ({ ...state, user }));
      if (query === null) {
        if (user?.role === 2) {
          setConnectionFilter('');
          setClientDropdownText('Alla klienter');
        } else {
          setConnectionFilter('connected');
        }
      }
    };

    getCompanyStatuses();
  }, []);

  function closeClientDropdownIfOpen() {
    if (clientDropdownOpen) {
      setClientDropdownOpen(false);
    }
  }

  const [filteredClients, setFilteredClients] = useState([]);
  useEffect(() => {
    const connectionFiltered =
      connectionFilter !== ''
        ? clients.filter((client) => {
            return connectionFilter === 'connected'
              ? client.unconnected === false
              : connectionFilter === 'unconnected'
              ? client.unconnected === true
              : false;
          })
        : clients;
    const queryFiltered = query
      ? connectionFiltered.filter((client) => {
          return (
            client.name?.toLowerCase().includes(query?.toLowerCase()) ||
            client.organizationNumber.toString().includes(query?.toLowerCase())
          );
        })
      : connectionFiltered;
    setFilteredClients(queryFiltered);
  }, [query, connectionFilter, clients]);

  const redirectToCompanyInfo = (clientId) => {
    history.push(`/companyinfo/${clientId}/score`);
  };

  const [sortOrder, setSortOrder] = useState('asc');

  const sortStringCompare = (a, b) => {
    const stringA = a?.toUpperCase();
    const stringB = b?.toUpperCase();

    if (stringA < stringB) {
      return -1;
    }
    if (stringA > stringB) {
      return 1;
    }
    return 0;
  };

  const sortByName = () => {
    if (sortOrder === 'asc') {
      filteredClients.sort((a, b) => {
        return sortStringCompare(a.name, b.name);
      });
      setSortOrder('desc');
    } else if (sortOrder === 'desc') {
      filteredClients.sort((a, b) => {
        return sortStringCompare(a.name, b.name) * -1;
      });
      setSortOrder('asc');
    }
  };

  const getScoreForClient = (clientId) => {
    for (let i = 0; i < scores.length; i += 1) {
      if (scores[i] !== null && scores[i].clientId === clientId) {
        return scores[i];
      }
    }
    return null;
  };

  const sortByScore = () => {
    if (sortOrder === 'asc') {
      filteredClients.sort((a, b) => {
        const aScore = getScoreForClient(a.id);
        const bScore = getScoreForClient(b.id);

        const aValue =
          !a.externalConnectionActive && !a.bankConnectionActive
            ? `G${aScore?.overallScore ?? 'F'}`
            : aScore?.overallScore ?? 'F';
        const bValue =
          !b.externalConnectionActive && !b.bankConnectionActive
            ? `G${bScore?.overallScore ?? 'F'}`
            : bScore?.overallScore ?? 'F';

        return sortStringCompare(aValue, bValue);
      });
      setSortOrder('desc');
    } else if (sortOrder === 'desc') {
      filteredClients.sort((a, b) => {
        const aScore = getScoreForClient(a.id);
        const bScore = getScoreForClient(b.id);

        const aValue =
          !a.externalConnectionActive && !a.bankConnectionActive
            ? `G${aScore?.overallScore ?? 'F'}`
            : aScore?.overallScore ?? 'F';
        const bValue =
          !b.externalConnectionActive && !b.bankConnectionActive
            ? `G${bScore?.overallScore ?? 'F'}`
            : bScore?.overallScore ?? 'F';

        return sortStringCompare(aValue, bValue) * -1;
      });
      setSortOrder('asc');
    }
  };

  return (
    <div className="dashboard-wrapper">
      <div className="dashboard-nav-container">
        <LabeledInfo label="Senast hämtade:" info={lastUpdated} />
        <div className="dash-add-client">
          {authState.user?.role === 10 && (
            <XpektorButton
              content="Lägg till med bokföring (System Admin)"
              onClick={() => {
                setClientToCreate([]);
                setAction('createClient');
                setShowAuthenticateClient(true);
              }}
            />
          )}
          {authState.user?.role > 0 && (
            <XpektorButton
              content="Lägg till klient"
              onClick={() => {
                setShowCreateAuthLinkModal(true);
              }}
            />
          )}
        </div>
      </div>
      {authState.user?.role > 0 && (
        <div className="dashboard-nav-container">
          <Dropdown
            title={clientDropdownText}
            open={clientDropdownOpen}
            onClick={() => {
              setClientDropdownOpen(!clientDropdownOpen);
            }}
            onClickOutside={() => setClientDropdownOpen(false)}
          >
            <Dropdown.Item
              content="Kopplade klienter"
              onClick={() => {
                setClientDropdownText('Kopplade klienter');
                setConnectionFilter('connected');
                closeClientDropdownIfOpen();
              }}
            />
            <Dropdown.Item
              content="Ej signerade"
              onClick={() => {
                setClientDropdownText('Ej signerade');
                setConnectionFilter('unconnected');
                closeClientDropdownIfOpen();
              }}
            />
            <Dropdown.Item
              content="Alla klienter"
              onClick={() => {
                setClientDropdownText('Alla klienter');
                setConnectionFilter('');
                closeClientDropdownIfOpen();
              }}
            />
          </Dropdown>
        </div>
      )}

      <div className="dash-table">
        <LoadingWindow active={isLoading} status={loadingText} />
        <CompanyTable
          order={sortOrder}
          sortByName={sortByName}
          sortByScore={sortByScore}
        >
          {isLoading === false &&
            filteredClients.map((data) => {
              const clientScore = getScoreForClient(data.id);

              return (
                <CompanyTable.Item
                  client={data}
                  score={clientScore}
                  onClick={() => redirectToCompanyInfo(data.id)}
                  key={data.id}
                />
              );
            })}
        </CompanyTable>
      </div>
      {showAuthenticateClient && (
        <ConnectAccountingModal
          handleClose={() => setShowAuthenticateClient(false)}
          action={action}
          client={clientToCreate}
          authLevel={authState.user?.role}
        />
      )}
      {showCreateAuthLinkModal && (
        <CreateAuthLinkModal
          handleClose={() => setShowCreateAuthLinkModal(false)}
        />
      )}
    </div>
  );
}
