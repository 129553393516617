/* eslint-disable react/prop-types */
import './Dropdown.scss';
import { useRef, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons';

function useClickOutside(ref, onClickOutside) {
  useEffect(() => {
    /**
     * Invoke Function onClick outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        onClickOutside();
      }
    }
    // Bind
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // dispose
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, onClickOutside]);
}

export default function Dropdown({
  children,
  title,
  titleIcon,
  componentIcon,
  open,
  onClick,
  onClickOutside,
  right,
}) {
  const contentRef = useRef();
  useClickOutside(contentRef, () => {
    onClickOutside();
  });
  return (
    <div className="xpektor-dropdown" ref={contentRef}>
      <button type="button" onClick={onClick} className="drop-button">
        {titleIcon ? <FontAwesomeIcon icon={titleIcon} /> : componentIcon ?? ''}
        <p className="title">{title}</p>
        <FontAwesomeIcon icon={open ? faChevronUp : faChevronDown} />
      </button>
      <div
        className={`dropdown-content ${right ? 'align-right' : ''} ${
          open ? 'open' : ''
        }`}
      >
        {children}
      </div>
    </div>
  );
}

Dropdown.Item = function Item({ content, onClick }) {
  return (
    <button type="button" onClick={onClick} className="dropdown-item">
      {content}
    </button>
  );
};
